import './VideoPreview.css';
import React from 'react';
import {useParams} from 'react-router-dom';

function VideoPreview() {
    const {id} = useParams();
    const data = JSON.parse(localStorage.getItem(id));

    const file = `/files/${data.src}`;

    const deleteVideo = () => {
        if (!window.confirm('Вы уверены что хотите удалить видео?')) {
            return;
        }

        fetch(`/api/qr/video/${id}`, {
            method: 'DELETE',
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    localStorage.removeItem(id);
                    window.location.replace('/' + id);
                } else {
                    alert('Что то пошло не так, попробуйте еще раз, или напишите в поддержку');
                }
            })
            .catch(() => {
                alert('Что то пошло не так, попробуйте еще раз, или напишите в поддержку');
            });
    }

    return (
        <section className="section fade-in">
            <video className="video" controls preload={'auto'} src={file}></video>
            <a href={file} className="btn" download={true}>Скачать</a>
            <button className="btn btn_danger" onClick={deleteVideo}>Удалить</button>
        </section>
    );

}

export default VideoPreview;
