import {Link, useParams} from 'react-router-dom';
import {keyboard} from '@testing-library/user-event/dist/keyboard';
import succes from './success.svg';

export default function Success() {
    const { id } = useParams();
    return (
        <div className="section fade-in">
            <h1 className="title">Готово!</h1>
            <p className="text">Ваш QR готов к использованию</p>
            <img src={succes} alt="Успешно"/>
            <Link to={`/${id}`} reloadDocument={true} className="btn">Обновить страницу</Link>
        </div>
    );
}
